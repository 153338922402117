<template>
  <div class="h-100">
    <div class="d-flex align-items-center flex-column justify-content-center h-100">
      <div
        id="login-card"
        class="card border-0 shadow"
      >
        <div
          v-if="authError"
          class="card-header bg-danger-light text-danger border-0"
        >
          <div class="row align-items-center">
            <div class="col-auto">
              <i class="fas fa-exclamation-triangle text-danger" />
            </div>
            <div class="col pl-0">
              <h6 class="mb-0">
                Zeitüberschreitung
              </h6>
              beim Warten auf Aktivierung des Security-Keys.
            </div>
          </div>
        </div>
        <div class="card-body p-5 text-center">
          <h5
            v-if="authPending"
            class="card-title text-center"
          >
            Melden Sie sich mit ihren Security-Key an
          </h5>
          <h5
            v-if="registrationPending"
            class="card-title text-center"
          >
            Registrieren Sie ihren Security-Key
          </h5>
          <p class="text-center mb-4">
            Drücken Sie den blinkenden Button auf dem Security-Key. Falls dieser nicht blinkt, stecken Sie Ihn erneut ein.
          </p>

          <p v-if="!authError && !registrationError && (authPending || registrationPending)">
            <i class="fas fa-spinner fa-pulse" /> Drücken Sie den blickenden Button auf dem Security-Key
          </p>
          <div class="form-row">
            <div class="col">
              <button
                class="btn btn-secondary w-100"
                type="button"
                @click="goBack()"
              >
                Zurück
              </button>
            </div>
            <div
              v-if="authError || registrationError"
              class="col"
            >
              <button
                class="btn btn-primary w-100"
                @click="retry()"
              >
                Erneut versuchen
              </button>
            </div>
          </div>

          <router-link
            v-if="!isRegistration && otherMethodsAvailable"
            :to="{name: '2faSelect', query: $route.query}"
            class="d-block mt-3"
          >
            Andere Methode verwenden
          </router-link>

          <p v-if="authVerification.accessToken">
            Erfolgreich
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import feathers from '@/api'
import u2fApi from 'u2f-api'

export default {
  data () {
    return {
      registrationError: null,
      registrationPending: false,
      registrationSuccessful: false,
      authPending: false,
      authError: null,
      authVerification: {},
      isRegistration: false
    }
  },
  computed: {
    twoFactor () {
      return this.$store.getters['auth/user'].user.twoFactor
    },
    client () {
      return this.$store.getters['clients/find']().data[0]
    },
    isSmsAvailable () {
      const globalAvailable = this.client && this.client.twoFactor && this.client.twoFactor.SMS && this.client.twoFactor.SMS.enabled
      const userAvailable = this.twoFactor.SMS && this.twoFactor.SMS.mobileNumber && this.twoFactor.SMS.secret
      return globalAvailable && userAvailable
    },
    isTotpAvailable () {
      const globalAvailable = this.client && this.client.twoFactor && this.client.twoFactor.TOTP && this.client.twoFactor.TOTP.enabled
      const userAvailable = this.twoFactor && this.twoFactor.TOTP && this.twoFactor.TOTP.secret
      return globalAvailable && userAvailable
    },
    otherMethodsAvailable () {
      return this.isSmsAvailable || this.isTotpAvailable
    }
  },
  async created () {
    const user = this.$store.getters['auth/user'].user
    if (!user) return this.$router.push('/login')

    if (!user.twoFactor.U2F) {
      this.$set(this.$store.state.auth.user.user.twoFactor, 'U2F', {})
    }
    const U2F = user.twoFactor.U2F
    if (U2F.publicKey && U2F.keyHandle) {
      this.isRegistration = false
      await this.auth()
      if (this.authVerification.accessToken) {
        if (this.$route.query.redirect) this.$router.push(this.$route.query.redirect)
        else this.$router.push({ name: 'Dashboard' })
      }
    } else {
      this.isRegistration = true
      await this.register()
      if (this.authVerification.accessToken) {
        if (this.$route.query.redirect) this.$router.push(this.$route.query.redirect)
        else this.$router.push({ name: 'Dashboard' })
      }
    }
  },
  methods: {
    async goBack () {
      if (this.isRegistration) {
        await this.$router.push(this.$route.query.redirect || '/')
      } else {
        await this.$store.dispatch('auth/logout')
        location.href = '/login'
      }
    },
    async retry () {
      this.registrationError = null
      this.registrationSuccessful = false
      this.authError = null

      if (this.isRegistration) {
        await this.register()
        if (this.authVerification.accessToken) {
          if (this.$route.query.redirect) this.$router.push(this.$route.query.redirect)
          else this.$router.push({ name: 'Dashboard' })
        }
      } else {
        await this.auth()
        if (this.authVerification.accessToken) {
          if (this.$route.query.redirect) this.$router.push(this.$route.query.redirect)
          else this.$router.push({ name: 'Dashboard' })
        }
      }
    },
    async register () {
      this.registrationPending = true
      try {
        const { registrationRequest } = await feathers.service('u2f-registration').get({})
        const registrationResponse = await u2fApi.register([registrationRequest])
        this.authVerification = await this.$store.dispatch('auth/authenticate', {
          strategy: '2fa',
          accessToken: this.$store.state.auth.accessToken,
          twoFactorMethod: 'u2f',
          isRegistration: true,
          u2fSignature: { registrationRequest, registrationResponse }
        })
      } catch (err) {
        console.error(err)
        this.registrationError = err
      } finally {
        this.registrationPending = false
      }
    },

    async auth () {
      this.authPending = true
      try {
        const { authRequest } = await feathers.service('u2f-authentication').get({})
        authRequest.keyHandle = authRequest.keyHandle.keyHandle
        const authResponse = await u2fApi.sign([authRequest])
        this.authVerification = await this.$store.dispatch('auth/authenticate', {
          strategy: '2fa',
          accessToken: this.$store.state.auth.accessToken,
          twoFactorMethod: 'u2f',
          u2fSignature: { authRequest, authResponse }
        })
      } catch (err) {
        console.error(err)
        this.authError = err
      } finally {
        this.authPending = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#login-card {
  max-width: 500px;
}
</style>
